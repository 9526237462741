import React from 'react';
//Routing
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//Style
import { GlobalStyle } from './GlobalStyle';
// API
import API from './API';
//Hooks
import { useSystemFetch } from './hooks/useSystemFetch';
//Components
import Home from './components/Home';
import NotFound from './components/NotFound';
import Maintenance from './components/Maintenance';
import Login from './components/Login';
import Logout from './components/Logout';
import Profile from './components/Profile';
import ScrollToTop from './components/ScrollToTop'
import Settings from './components/Settings';
import Dashboard from './components/Dashboard';
import ImageDashboard from './components/ImageDashboard';
//Functions
import UserFunctions from './components/UserFunctions';
// Context
import UserProvider from './context';


const App = () => {
  
  const {
    state,
    loading, 
    error,
  } = useSystemFetch();

  if(loading) return ''
  if(error) return <div>Something went wrong...</div>

  return (
    <Router>
      <UserProvider>
        <ScrollToTop>
          {
            (!UserFunctions.getSessionHash()) ?
              <Routes>
                <Route path='*' element={<Login />} />
              </Routes>
            :null
          }
          {
            (UserFunctions.getSessionHash()) ?
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/logout' element={<Logout />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/login/:code' element={<Login />} />
                  <Route path='/@:userName' element={<Profile />} />
                  <Route path='/settings' element={<Settings />} />

                  <Route path='/dashboard' element={<Dashboard />} />

                  <Route path='/image' element={<ImageDashboard />} />
                  <Route path='/image/:imageId/:fullFileName' element={<ImageDashboard />} />

                  <Route path='/search/:search' element={<Home />} />

                  <Route path='*' element={<NotFound />} />
                </Routes>
            :null
          }
        </ScrollToTop>
        <GlobalStyle />
      </UserProvider>
    </Router>
  )
};

export default App;
import React, { useState, useEffect } from 'react';
import API from '../../API';
// Config
import { IMG_URL } from '../../config'
//Functions
import UserFunctions from '../UserFunctions';
//Components
import Spinner from '../Spinner';
import GetText from '../GetText';
import UserImage from '../UserImage';
import UserDefaultImage from '../../images/user/profile-img-default.png'
//Hook
import { useSystemFetch } from '../../hooks/useSystemFetch';
// Styles
import { Wrapper } from './SystemSettings.styles';
import Button from '../Button';
import Input from '../Input';

const SystemSettings = ({userHash}) => {

    const [userNameMinLength, setUserNameMinLength] = useState('');
    const [userNameMaxLength, setUserNameMaxLength] = useState('');
    const [passwordMinLength, setPasswordMinLength] = useState('');

    const [postTitleMinLength, setPostTitleMinLength] = useState('');
    const [postTitleMaxLength, setPostTitleMaxLength] = useState('');
    const [postAnswerMinLength, setPostAnswerMinLength] = useState('');
    const [postAnswerMaxLength, setPostAnswerMaxLength] = useState('');

    const [postTitlePreviewMaxLength, setPostTitlePreviewMaxLength] = useState('');
    const [postAnswerPreviewMaxLength, setPostAnswerPreviewMaxLength] = useState('');

    const [postHashtagMinAmount, setPostHashtagMinAmount] = useState('');
    const [postHashtagMaxAmount, setPostHashtagMaxAmount] = useState('');

    const [postAnswerCanDeleteTime, setPostAnswerCanDeleteTime] = useState('');

    const [postAnswerVoteCoolDown, setPostAnswerVoteCoolDown] = useState('');

    const [login, setLogin] = useState(false);
    const [register, setRegister] = useState(false);
    const [maintenance, setMaintenance] = useState(false);

    const [success, setSuccess] = useState(false);

    const handleInput = e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        if(name === 'updateUserNameMinLength') setUserNameMinLength(value);
        if(name === 'updateUserNameMaxLength') setUserNameMaxLength(value);
        if(name === 'updatePasswordMinLength') setPasswordMinLength(value);

        if(name === 'updatePostTitleMinLength') setPostTitleMinLength(value);
        if(name === 'updatePostTitleMaxLength') setPostTitleMaxLength(value);
        if(name === 'updatePostAnswerMinLength') setPostAnswerMinLength(value);
        if(name === 'updatePostAnswerMaxLength') setPostAnswerMaxLength(value);
        
        if(name === 'updatePostTitlePreviewMaxLength') setPostTitlePreviewMaxLength(value);
        if(name === 'updatePostAnswerPreviewMaxLength') setPostAnswerPreviewMaxLength(value);

        if(name === 'updatePostHashtagMinAmount') setPostHashtagMinAmount(value);
        if(name === 'updatePostHashtagMaxAmount') setPostHashtagMaxAmount(value);

        if(name === 'updatePostAnswerCanDeleteTime') setPostAnswerCanDeleteTime(value);

        if(name === 'updatePostAnswerVoteCoolDown') setPostAnswerVoteCoolDown(value);

    };

    const handleLogin = e => {
        var valueLogin = e.currentTarget.value;

        if(valueLogin != null) {

            setLogin(valueLogin);
        }
    }
    const handleRegister = e => {
        var valueRegister = e.currentTarget.value;

        if(valueRegister != null) {

            setRegister(valueRegister);
        }
    }
    const handleMaintenance = e => {
        var valueMaintenance = e.currentTarget.value;

        if(valueMaintenance != null) {

            setMaintenance(valueMaintenance);
        }
    }

    const handleSubmit = async () => {

        try {
            setSuccess(false);

            const updateSystem = await API.updateSystem(userHash, userNameMinLength, userNameMaxLength, passwordMinLength, login, register, maintenance,
                                                        postTitleMinLength, postTitleMaxLength, postAnswerMinLength, postAnswerMaxLength, postTitlePreviewMaxLength, postAnswerPreviewMaxLength,
                                                        postHashtagMinAmount, postHashtagMaxAmount, postAnswerCanDeleteTime, postAnswerVoteCoolDown);

            if(updateSystem.results[0].success === true) {
                setSuccess(true);
            }

        } catch (error) {
            setSuccess(false);
        }
    };

    const {
        state,
        loading, 
        error
    } = useSystemFetch(userHash);

    useEffect(() => {
        if((state) && (!loading)) {
            if(!userNameMinLength) {
                setUserNameMinLength(state.userNameMinLength);
            }
            if(!userNameMaxLength) {
                setUserNameMaxLength(state.userNameMaxLength);
            }
            if(!passwordMinLength) {
                setPasswordMinLength(state.passwordMinLength);
            }

            if(!postTitleMinLength) {
                setPostTitleMinLength(state.postTitleMinLength);
            }
            if(!postTitleMaxLength) {
                setPostTitleMaxLength(state.postTitleMaxLength);
            }
            if(!postAnswerMinLength) {
                setPostAnswerMinLength(state.postAnswerMinLength);
            }
            if(!postAnswerMaxLength) {
                setPostAnswerMaxLength(state.postAnswerMaxLength);
            }

            if(!postTitlePreviewMaxLength) {
                setPostTitlePreviewMaxLength(state.postTitlePreviewMaxLength);
            }
            if(!postAnswerPreviewMaxLength) {
                setPostAnswerPreviewMaxLength(state.postAnswerPreviewMaxLength);
            }

            if(!postHashtagMinAmount) {
                setPostHashtagMinAmount(state.postHashtagMinAmount);
            }
            if(!postHashtagMaxAmount) {
                setPostHashtagMaxAmount(state.postHashtagMaxAmount);
            }

            if(!postAnswerCanDeleteTime) {
                setPostAnswerCanDeleteTime(state.postAnswerCanDeleteTime);
            }

            if(!postAnswerVoteCoolDown) {
                setPostAnswerVoteCoolDown(state.postAnswerVoteCoolDown);
            }

            if(!login) {
                if(state.login == 0) {
                    setLogin('false');
                }
                if(state.login == 1) {
                    setLogin('true');
                }
            }
            if(!register) {
                if(state.register == 0) {
                    setRegister('false');
                }
                if(state.register == 1) {
                    setRegister('true');
                }
            }
            if(!maintenance) {
                if(state.maintenance == 0) {
                    setMaintenance('false');
                }
                if(state.maintenance == 1) {
                    setMaintenance('true');
                }
            }   
        }
    }, [state, loading]);

    if(loading) return <Spinner />
    if(error) return <div>Something went wrong...</div>

    return (
        <>
            <Wrapper>
                <div className="container-main container">
                    <div className="settings-wrapper">
                        <div id="general" className="settings-content">
                            <h1><GetText textName='systemSettings' /></h1>
                            <h2>{<GetText textName='register' />}</h2>
                            <h3>
                                {
                                    success ?
                                            <span className='successTrue'>{<GetText textName='saved' />}</span>
                                    :null
                                }
                            </h3>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='username' /> minLength</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={userNameMinLength} name='updateUserNameMinLength' placeholder={state.userNameMinLength} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='username' /> maxLength</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={userNameMaxLength} name='updateUserNameMaxLength' placeholder={state.userNameMaxLength} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='password' /> minLength</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={passwordMinLength} name='updatePasswordMinLength' placeholder={state.passwordMinLength} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="postAndAnswer" className="settings-content">
                            <h2>{<GetText textName='post' />} / <GetText textName='answer' /></h2>
                            <h3>
                                {
                                    success ?
                                            <span className='successTrue'>{<GetText textName='saved' />}</span>
                                    :null
                                }
                            </h3>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='post' /> minLength</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={postTitleMinLength} name='updatePostTitleMinLength' placeholder={state.postTitleMinLength} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='post' /> maxLength</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={postTitleMaxLength} name='updatePostTitleMaxLength' placeholder={state.postTitleMaxLength} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='answer' /> minLength</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={postAnswerMinLength} name='updatePostAnswerMinLength' placeholder={state.postAnswerMinLength} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='answer' /> maxLength</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={postAnswerMaxLength} name='updatePostAnswerMaxLength' placeholder={state.postAnswerMaxLength} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='post' /> Preview maxLength</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={postTitlePreviewMaxLength} name='updatePostTitlePreviewMaxLength' placeholder={state.postTitlePreviewMaxLength} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='answer' /> Preview maxLength</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={postAnswerPreviewMaxLength} name='updatePostAnswerPreviewMaxLength' placeholder={state.postAnswerPreviewMaxLength} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='post' /> hashtag minAmount</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={postHashtagMinAmount} name='updatePostHashtagMinAmount' placeholder={state.postHashtagMinAmount} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='post' /> hashtag maxAmount</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={postHashtagMaxAmount} name='updatePostHashtagMaxAmount' placeholder={state.postHashtagMaxAmount} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='answer' /> canDeleteTime (Minutes)</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={postAnswerCanDeleteTime} name='updatePostAnswerCanDeleteTime' placeholder={state.postAnswerCanDeleteTime} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='vote' /> cooldown (Seconds)</label>
                                </aside>
                                <div className="settings-change">
                                    <div  style={{width: "100%"}}>
                                        <Input type='number' min={0} value={postAnswerVoteCoolDown} name='updatePostAnswerVoteCoolDown' placeholder={state.postAnswerVoteCoolDown} autocomplete='off' onChange={handleInput} />
                                        <div style={{width: "100%"}}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="settings-content">
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='login' /></label>
                                </aside>
                                <div className="settings-change">
                                    <div style={{width: "100%"}}>
                                        {
                                            (state.login == 1) ? 
                                                <select onChange={handleLogin}>
                                                    <option value='true'>On</option>
                                                    <option value='false'>Off</option>
                                                </select>
                                            :
                                                <select onChange={handleLogin}>
                                                    <option value='false'>Off</option>
                                                    <option value='true'>On</option>
                                                </select>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="settings-content">
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='register' /></label>
                                </aside>
                                <div className="settings-change">
                                    <div style={{width: "100%"}}>
                                        {
                                            (state.register == 1) ? 
                                                <select onChange={handleRegister}>
                                                    <option value='true'>On</option>
                                                    <option value='false'>Off</option>
                                                </select>
                                            :
                                                <select onChange={handleRegister}>
                                                    <option value='false'>Off</option>
                                                    <option value='true'>On</option>
                                                </select>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="settings-content">
                            <div className="settings-row">
                                <aside>
                                    <label><GetText textName='maintenanceTitle' /></label>
                                </aside>
                                <div className="settings-change">
                                    <div style={{width: "100%"}}>
                                        {
                                            (state.maintenance == 1) ? 
                                                <select onChange={handleMaintenance}>
                                                    <option value='true'>On</option>
                                                    <option value='false'>Off</option>
                                                </select>
                                            :
                                                <select onChange={handleMaintenance}>
                                                    <option value='false'>Off</option>
                                                    <option value='true'>On</option>
                                                </select>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label></label>
                                </aside>
                                <div className="settings-change">
                                    <Button type='button' text={<GetText textName='save' />} callback={handleSubmit} />
                                </div>
                            </div>
                            <div className="settings-row">
                                <aside>
                                    <label></label>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    )
};

export default SystemSettings;
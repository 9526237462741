import {
  API_URL,
  //API_KEY,
} from './config';

const apiSettings = {
  fetchSystem: async (userHash) => {
    const endpoint = `${API_URL}system?userHash=${userHash}`;
    return await (await fetch(endpoint)).json();
  },
  fetchHashtags: async (action, hashtagSelectedName, subHashtag) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'getHashtags', action: action, hashtagSelectedName: hashtagSelectedName, subHashtag: subHashtag })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
  },
  fetchImages: async (userHash, imageId, fullFileName) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'getImages', userHash: userHash, imageId: imageId, fullFileName: fullFileName })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
  },
  fetchUser: async (userName, userHash) => {
    const endpoint = `${API_URL}user?userName=${userName}&userHash=${userHash}`;
    return await (await fetch(endpoint)).json();
  },
  fetchText: async (textName, textLang) => {
    const endpoint = `${API_URL}content?textName=${textName}&textLang=${textLang}`;
    return await (await fetch(endpoint)).json();
  },
  fetchStats: async (userHash) => {
    const endpoint = `${API_URL}stats?userHash=${userHash}`;
    return await (await fetch(endpoint)).json();
  },
  vote: async (vote, answerId, userHash) => {
    const resultVote = `${API_URL}vote?vote=${vote}&answerId=${answerId}&userHash=${userHash}`;
    return await (await fetch(resultVote)).json();
  },
  userList: async (userHash) => {
    const data = `${API_URL}userList?userHash=${userHash}`;
    return await (await fetch(data)).json();
  },
  adminStats: async (userHash) => {

    var apiRequest = 'adminStats';
    return await (await fetch(`${API_URL}getRequest?API=${apiRequest}&userHash=${userHash}`)).json();

  },
  updateHashtag: async (action, userHash, hashtag, hashtagObject, hashtagNewName, imageId) => {

    var apiRequest = 'updateHashtag';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: apiRequest, action: action, userHash: userHash, hashtag: hashtag, hashtags: hashtagObject, hashtagNewName: hashtagNewName, imageId: imageId })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  updateImage: async (action, userHash, imageId, fullFileName, newFullFileName) => {

    var apiRequest = 'updateImage';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: apiRequest, action: action, userHash: userHash, imageId: imageId, fullFileName: fullFileName, newFullFileName: newFullFileName })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  moderation: async (action, postId, answerId, userHash, reportId, postTitle, postAnswer, userId) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'moderation', action: action,
                            postId: postId, answerId: answerId, userHash: userHash, reportId: reportId, post: postTitle, answer: postAnswer,
                            userId: userId })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
  updateSettings: async (userName, userHash, userNameNew, email, passwordOld, passwordNew, darkMode) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'updateSettings', userName: userName, userHash: userHash, userNameNew: userNameNew, userEmailNew: email, userPasswordOld: passwordOld, userPasswordNew: passwordNew })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  updateSystem: async (userHash, userNameMinLength, userNameMaxLength, passwordMinLength, login, register, maintenance,
                      postTitleMinLength, postTitleMaxLength, postAnswerMinLength, postAnswerMaxLength, postTitlePreviewMaxLength, postAnswerPreviewMaxLength,
                      postHashtagMinAmount, postHashtagMaxAmount, postAnswerCanDeleteTime, postAnswerVoteCoolDown) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'updateSystem', userHash: userHash, userNameMinLength: userNameMinLength, userNameMaxLength: userNameMaxLength, passwordMinLength: passwordMinLength, login: login, register: register, maintenance: maintenance,
                            postTitleMinLength: postTitleMinLength, postTitleMaxLength: postTitleMaxLength, postAnswerMinLength: postAnswerMinLength, postAnswerMaxLength: postAnswerMaxLength, postTitlePreviewMaxLength: postTitlePreviewMaxLength, postAnswerPreviewMaxLength: postAnswerPreviewMaxLength,
                            postHashtagMinAmount: postHashtagMinAmount, postHashtagMaxAmount: postHashtagMaxAmount,
                            postAnswerCanDeleteTime: postAnswerCanDeleteTime,
                            postAnswerVoteCoolDown: postAnswerVoteCoolDown })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  updateUser: async (userHash, userId, userName, userEmail, userVerified, userBanned) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'updateUser', userHash: userHash, userId: userId, userName: userName, userEmail: userEmail, userVerified: userVerified, userBanned: userBanned })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  login: async (username, password, code, authenticatorPin) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'authentication', action: 'login', username: username, password: password, emailCode: code, authenticatorPin: authenticatorPin })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();
    
  },
  logout: async (userId, userHash) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ API: 'authentication', action: 'logout', userId: userId, userHash: userHash })
    };
    return await (await fetch(`${API_URL}postRequest`, requestOptions)).json();

  },
};

export default apiSettings;
import { React, useState } from 'react';
import { Link } from 'react-router-dom';
// Config
import { USER_IMG_URL } from '../config'
// Components
import Button from './Button';
import Spinner from './Spinner';
import SearchBar from './SearchBar';
import GetText from './GetText';
import Header from './Header';
import Footer from './Footer'
import Wrapper from './Wrapper/'
import UserImage from './UserImage/'
import UserDefaultImage from '../images/user/profile-img-default.png'
//Styles
import { Wrapper as HomeWrapper } from './Home/Home.styles';
//Functions
import UserFunctions from './UserFunctions';


const Home = () => {

    const [loading, setLoading] = useState(false);
     
    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
            <SearchBar setSearchTerm={''} userHash={UserFunctions.getSessionHash()}/>

            <HomeWrapper id='homeWrapper'>
                {
                    /* LOGGED IN HEADER START PAGE */
                    (UserFunctions.getSessionHash()) ? (
                        <div className='homeNavigation'>
                            <>
                            <Button className='buttonNavigation default' callback={() => { '' }} text={<GetText textName='trends' />}/>
                            </>
                            <br></br>
                            <>
                            <Button className='buttonNavigation topUsersButton default' callback={() => { '' }} text={<GetText textName='rankingTopUsers' />}/>
                            </>
                            <div className='dropdown'>
                                <span><GetText textName='filter' /> <i className="fas fa-sort-down"></i></span>
                                <div className="dropdown-content">
                                    <Link to={`/`}>
                                        <div><GetText textName='filterPostsAll' /></div>
                                    </Link>
                                    <Link to={`/!noAnswer`}>
                                        <div><GetText textName='filterPostsNoAnswer' /></div>
                                    </Link>
                                    <Link to={`/!random`}>
                                        <div><GetText textName='filterPostsRandom' /></div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                    :null
                    /* LOGGED IN HEADER START PAGE END */
                }
                {
                    loading ? (
                        <Spinner />
                    ):null
                }
            </HomeWrapper>
            <Footer />
        </>
    )
};
export default Home;
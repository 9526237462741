import { useState, useEffect } from 'react';
import API from '../API';
import UserFunctions from '../components/UserFunctions';

export const useSystemFetch = (userHash) => {
    const [state, setState] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const fetchSystem = async() => {
        try {
            setLoading(true);
            setError(false);

            const data = await API.fetchSystem(userHash);
            
            setState(data.results[0]);
        } catch (error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSystem(userHash); 
    }, [userHash]);

    return { state, loading, error };
}

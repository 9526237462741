import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
// Config
import { IMG_URL } from '../config'
//Functions
import UserFunctions from './UserFunctions';
import Wrapper from './Wrapper'
// Components
import Reports from './Reports';
import Userlist from './Userlist';
import Stats from './Stats';
import Header from './Header';
import Footer from './Footer';
import { Wrapper as DashboardWrapper, Content } from './Dashboard/Dashboard.styles';
import GetText from './GetText';

const Dashboard = () => {

    const navigate = useNavigate();

    var secretCode = null;

    return (
        <>
            <Header userName={UserFunctions.getUserName()} />
                <Wrapper>
                    <DashboardWrapper>
                        <Content>
                            {
                                /*
                                    <h2>Authenticator</h2>
                                    <img src={'https://www.authenticatorapi.com/pair.aspx?AppName=Libfast&AppInfo='+UserFunctions.getUserName()+'&SecretCode='+secretCode} style={{display:'none'}}/>
                                */
                            }
                            <h2>Links</h2>
                            <p><Link to='/image'><GetText textName={'image'} /></Link></p>
                      </Content>
                        <Content>
                            <h2>Stats</h2>
                            <Stats userHash={UserFunctions.getSessionHash()}/>
                        </Content>
                        <Content>
                            <h2>Reports</h2>
                            <Reports userHash={UserFunctions.getSessionHash()}/>
                        </Content>
                        <Content>
                            <h2>Userlist</h2>
                            <Userlist userHash={UserFunctions.getSessionHash()}/>
                        </Content>
                    </DashboardWrapper>
                </Wrapper>
            <Footer />
        </>
    )
};

export default Dashboard;